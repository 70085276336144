import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import MenuItem from '@mui/material/MenuItem';
import { InputAdornment } from '@mui/material';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import ImagemMotorista from './CardImage.tsx'
import { Dialog } from '@mui/material';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';
import * as process from 'process';


const initialRows: GridRowsProp = [
  {
    id_driver: '',
    nome: '',
    nascimento: '',
    telefone: '',
    ncpf: '',
    nrg: '',
    ncnh: '',
    salario: '',
    rua: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: '',
    numero_casa: '',
    situacao: '',
    observacao: '',
    agregado: '',
    imagem: null as File | null,
  },
];

export default function GridDriver() {
  const [rows, setRows] = React.useState(initialRows);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState<Blob | null>(null);
  const [fileName, setFileName] = useState('');

  const [showSaveCancelButtons, setShowSaveCancelButtons] = React.useState(true);
  const [showUpdateCancelButtons, setShowUpdateCancelButtons] = React.useState(false);

  const opcoesSituacao = [
    {
      value: 'Ativo',
      label: 'Ativo',
    },
    {
      value: 'Inativo',
      label: 'Inativo',
    },
  ];

  const opcoesAgregado = [
    {
      value: 'Sim',
      label: 'Sim',
    },
    {
      value: 'Não',
      label: 'Não',
    },
  ];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      setDados({ ...dados, imagem: file });
    }
  };

  const [dados, setDados] = React.useState({
    id_driver: '',
    nome: '',
    nascimento: '',
    telefone: '',
    ncpf: '',
    nrg: '',
    ncnh: '',
    salario: '',
    rua: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: '',
    numero_casa: '',
    situacao: '',
    observacao: '',
    agregado: '',
    imagem: null as File | null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_LINK_DRIVER}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`
          }
        });
        setRows(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    // Busca os dados inicialmente
    fetchData();

    // Configura o intervalo para buscar dados regularmente
    const interval = setInterval(fetchData, process.env.REACT_APP_INTERVAL_TIME); // A cada 5 segundos (5000 milissegundos)

    // Limpa o intervalo quando o componente é desmontado para evitar vazamento de memória
    return () => clearInterval(interval);
  }, []);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleEditClick = (id_driver) => () => {
    const selectedRow = rows.find(row => row.id_driver === id_driver);
    if (selectedRow) {
      setDados(selectedRow);
      setOpenDialog(true);
      setShowSaveCancelButtons(false);
      setShowUpdateCancelButtons(true);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleShowClick = (id_driver) => () => {
    const selectedRow = rows.find(row => row.id_driver === id_driver);
    if (selectedRow) {
      setDados(selectedRow);
      setOpenDialog(true);
      setShowSaveCancelButtons(true);
      setShowUpdateCancelButtons(false);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleChangeOpen = (event) => {
    const { name, value } = event.target;
    setDados((prevDados) => ({ ...prevDados, [name]: value }));
  };


  const handleEditSubmit = async (event) =>{
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_DRIVER_UPDATE}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }

    setOpenDialog(false);
  };




  const columns: GridColDef[] = [
    { field: 'nome', headerName: 'Nome', width: 310, editable: false },
    { field: 'situacao', headerName: 'Situação', width: 110, editable: false },
    { field: 'telefone', headerName: 'Telefone', width: 130, editable: false },
    { field: 'agregado', headerName: 'Agregado', width: 110, editable: false },
    { field: 'bairro', headerName: 'Bairro', width: 110, editable: false },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <React.Fragment>
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="Delete"
              onClick={handleShowClick(id)}
              color="inherit"
            />
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />

            {showSaveCancelButtons && (
              <>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  PaperProps={{
                    component: 'form',
                  }}
                >
                  <DialogTitle>Dados Motorista</DialogTitle>
                  <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <Grid container spacing={3}>
                      <Grid item xs={5}>
                        <ImagemMotorista imagemHex={dados.imagem} />
                      </Grid>
                      <Grid item xs={7}>
                        <b>Nome: </b> {dados.nome}
                        <br></br>
                        <br></br>
                        <b>Telefone: </b> {dados.telefone}
                        <br></br>
                        <br></br>
                        <b>Data de nascimento: </b> {dados.nascimento}
                        <br></br>
                        <br></br>
                        <b>Endereço: </b> {dados.rua}, {dados.numero_casa}, {dados.bairro} - {dados.cidade}, {dados.uf} -- {dados.cep}
                        <br></br>
                        <br></br>
                        <b>Situação: </b> {dados.situacao}
                        <br></br>
                        <br></br>
                        <b>Agregado: </b> {dados.agregado}
                        <br></br>
                        <br></br>
                        <b>Salário: </b> {dados.salario}
                        <br></br>
                        <br></br>
                        <b>Número CNH: </b> {dados.ncnh}
                        <br></br>
                        <br></br>
                        <b>Observação: </b> {dados.observacao}
                      </Grid>

                      <Grid item xs={6}>

                        Lista Documentos Motorista
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Fechar</Button>
                  </DialogActions>

                </Dialog>
              </>
            )}


            {showUpdateCancelButtons && (
              <>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  PaperProps={{
                    component: 'form',
                    onSubmit: handleEditSubmit
                  }}

                >
                  <DialogTitle>Edição Motorista</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Atualize as informações necessárias de um motorista
                    </DialogContentText>

                    <Box border={0} borderColor="primary.main" padding={0.5} alignItems={'baseline'}>
                      <br></br>
                      <b>Dados:</b>
                      <br></br>

                      <TextField
                        label="Nome Completo"
                        id="nome"
                        name="nome"
                        value={dados.nome}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                      />
                      <TextField
                        label="Data nascimento"
                        id="nascimento"
                        name="nascimento"
                        type="date"
                        value={dados.nascimento}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                      />

                      <InputMask
                        mask="(99) 99999-9999"
                        value={dados.telefone}
                        onChange={handleChangeOpen}
                        maskChar=" "
                      >
                        {() => (
                          <TextField
                            label="Telefone"
                            id="telefone"
                            name="telefone"
                            margin="normal"
                            style={{ marginRight: '20px' }}
                          />
                        )}
                      </InputMask>

                      <InputMask
                        mask="999.999.999-99"
                        value={dados.ncpf}
                        onChange={handleChangeOpen}
                        maskChar=" "
                      >
                        {() => (
                          <TextField
                            label="CPF"
                            id="ncpf"
                            name="ncpf"
                            margin="normal"
                            style={{ marginRight: '20px' }}
                          />
                        )}
                      </InputMask>

                      <InputMask
                        mask="99.999.999-9"
                        value={dados.nrg}
                        onChange={handleChangeOpen}
                        maskChar=" "
                      >
                        {() => (
                          <TextField
                            label="RG"
                            id="nrg"
                            name="nrg"
                            margin="normal"
                            style={{ marginRight: '20px' }}
                          />
                        )}
                      </InputMask>


                      <TextField
                        label="Numero CNH"
                        id="ncnh"
                        name="ncnh"
                        value={dados.ncnh}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ marginRight: '20px' }}
                      />

                    </Box>

                    <br></br>
                    <b>Endereço:</b>
                    <br></br>
                    <TextField
                      label="CEP"
                      id="cep"
                      name="cep"
                      value={dados.cep}
                      onChange={handleChangeOpen}
                      //onBlur={handleCEPBlur}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                      required
                    />
                    <TextField
                      label="Rua"
                      id="rua"
                      name="rua"
                      value={dados.rua}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                      required
                      disabled
                    />
                    <TextField
                      label="Bairro"
                      id="bairro"
                      name="bairro"
                      value={dados.bairro}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                      required
                      disabled
                    />
                    <TextField
                      label="Cidade"
                      id="cidade"
                      name="cidade"
                      value={dados.cidade}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                      required
                      disabled
                    />
                    <TextField
                      label="UF"
                      id="uf"
                      name="uf"
                      value={dados.uf}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                      required
                      disabled
                    />
                    <TextField
                      label="Complemento"
                      id="complemento"
                      name="complemento"
                      value={dados.complemento}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                      required
                    />
                    <TextField
                      label="Número"
                      id="numero_casa"
                      name="numero_casa"
                      value={dados.numero_casa}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                      required
                    />

                    <br></br>
                    <b>Informações:</b>
                    <br></br>

                    <TextField
                      select
                      label="Situação"
                      id="situacao"
                      name="situacao"
                      value={dados.situacao}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '40%', marginRight: '20px' }}
                      required
                    >
                      {opcoesSituacao.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      label="Agregado?"
                      id="agregado"
                      name="agregado"
                      value={dados.agregado}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '40%', marginRight: '20px' }}
                      required
                    >
                      {opcoesAgregado.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      label="Salário"
                      id="salario"
                      name="salario"
                      value={dados.salario}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                      required
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      }}
                    />

                    <br></br>
                    <b>Observações:</b>
                    <br></br>
                    <TextField
                      label="Observações"
                      id="observacao"
                      name="observacao"
                      value={dados.observacao}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '100%', marginRight: '20px' }}
                      multiline
                      rows={4}
                    />

                    <FormControl fullWidth>
                      <InputLabel shrink htmlFor="file-upload">
                      </InputLabel>
                      <Input
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: 'image/*' }}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="file-upload">
                        <Button variant="contained" component="span" fullWidth>
                          Foto motorista: {dados.imagem ? dados.imagem.name : 'Selecione uma imagem'}
                        </Button>
                      </label>
                    </FormControl>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button onClick={handleEditSubmit} type="submit">Atualizar</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </React.Fragment>
        ];
      },
    },
  ];
  return (
    <Box
      sx={{
        height: 600,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id_driver}
      />
    </Box>
  );
}
