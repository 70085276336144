
import auth from './firebaseconfig.tsx'


async function getIdToken() {
    try {
        const user = auth.currentUser; // Obtém o usuário atualmente autenticado
        if (user) {
            const idToken = await user.getIdToken(); // Obtém o idToken do usuário
            return idToken;
        } else {
            throw new Error('Usuário não autenticado');
        }
    } catch (error) {
        console.error('Erro ao obter o idToken:', error);
        throw error;
    }
}

export default getIdToken