import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useState } from 'react';
import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';
import * as process from 'process';

export default function FormCadTruck() {
  const [open, setOpen] = React.useState(false);

  const [dados, setDados] = useState({
    renavam: '',
    placa: '',
    ano: '',
    capacidadem3: '',
    cor: '',
    descricao: '',
    modelo: '',
    proprietario: '',
    tipo: '',
    transportadora: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };

  const handleClickOpen = (event) => {
    setDados({
    renavam: '',
      placa: '',
      ano: '',
      capacidadem3: '',
      cor: '',
      descricao: '',
      modelo: '',
      proprietario: '',
      tipo: '',
      transportadora: ''
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CAD_TRUCK_NEW_REGISTER}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} style={{}}>
        Novo Carro
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
      >
        <DialogTitle>Novo Carro</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha as informações necessárias para cadastrar um novo carro
          </DialogContentText>

          <TextField
            // fullWidth
            label="Placa"
            id="placa"
            name="placa"
            value={dados.placa}
            onChange={handleChange}
            margin="normal"
            // variant='standard'
            required
            style={{ marginRight: '20px' }}
          />
          <TextField
            // fullWidth
            label="renavam"
            id="renavam"
            name="renavam"
            value={dados.renavam}
            onChange={handleChange}
            margin="normal"
            // variant='standard'
            required
            style={{ marginRight: '20px' }}
          />
          <TextField
            // fullWidth
            label="Ano"
            id="ano"
            name="ano"
            // type="email"
            value={dados.ano}
            onChange={handleChange}
            margin="normal"
            // variant='standard'
            required
            style={{ marginRight: '20px' }}
          />
          <TextField
            // fullWidth
            label="Capacidade"
            id="capacidadem3"
            name="capacidadem3"
            value={dados.capacidadem3}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
          />
          <TextField
            // fullWidth
            label="Cor"
            id="cor"
            name="cor"
            value={dados.cor}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
          />
          <TextField
            // fullWidth
            label="Descrição"
            id="descricao"
            name="descricao"
            value={dados.descricao}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
          />
          <TextField
            // fullWidth
            label="Modelo"
            id="modelo"
            name="modelo"
            value={dados.modelo}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
          />
          <TextField
            // fullWidth
            label="Proprietario"
            id="proprietario"
            name="proprietario"
            value={dados.proprietario}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
          />
          <TextField
            // fullWidth
            label="Tipo"
            id="tipo"
            name="tipo"
            value={dados.tipo}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
          />
          <TextField
            // fullWidth
            label="Transportadora"
            id="transportadora"
            name="transportadora"
            value={dados.transportadora}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClose} type="submit">Cadastrar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}