import React, { useState, useEffect } from 'react';
import hexToBinary from 'hex-to-binary';
import { Button } from '@mui/material';

function hexToByteArray(hex) {
  const byteArray = new Uint8Array(hex.length / 2);
  for (let i = 0; i < hex.length; i += 2) {
    byteArray[i / 2] = parseInt(hex.substr(i, 2), 16);
  }
  return byteArray;
}


function getMimeType(fileName) {
  const extension = fileName.split('.').pop().toLowerCase();
  const mimeTypes = {
    'pdf': 'application/pdf',
    'txt': 'text/plain',
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'ppt': 'application/vnd.ms-powerpoint',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'mp3': 'audio/mpeg',
    'mp4': 'video/mp4',
    'avi': 'video/x-msvideo',
    'mov': 'video/quicktime',
    'zip': 'application/zip',
    'rar': 'application/x-rar-compressed'
    // Adicione mais tipos conforme necessário
  };
  return mimeTypes[extension] || 'application/octet-stream';
}

const FileSaverComponent = ({ hexData, fileName }) => {
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    const byteArray = hexToByteArray(hexData);

    const blob = new Blob([byteArray], { type: getMimeType(fileName) });

    const url = URL.createObjectURL(blob);

    setFileUrl(url);

    return () => URL.revokeObjectURL(url);
  }, [hexData, fileName]);

  return (
    <div>
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        {fileName}
      </a>

{/* 
      <Button href={fileUrl} download={fileName}>
        Baixar Anexo
      </Button> */}
    </div>

  );
};


export default FileSaverComponent