import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Dialog } from '@mui/material';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import axios from 'axios';
import getIdToken from '../../../utils/api_loker.tsx';
import * as process from 'process';
import FileSaverComponent from './file_viewer.tsx';

const initialRows: GridRowsProp = [];

export default function GridCNH() {
  const [rows, setRows] = React.useState(initialRows);
  const [showSaveCancelButtons, setShowSaveCancelButtons] = React.useState(true);
  const [showUpdateCancelButtons, setShowUpdateCancelButtons] = React.useState(false);

  const [dados, setDados] = React.useState({
    id: '',
    n_cnh: '',
    n_cpf: '',
    nome: '',
    data_validade: '',
    categoria: '',
    nome_arquivo: '',
    anexo: null as File | null
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_LINK_CNH}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`
          }
        });
        setRows(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, process.env.REACT_APP_INTERVAL_TIME);
    return () => clearInterval(interval);
  }, []);

  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    if (openDialog) {
    }
  }, [openDialog]);

  const handleEditClick = (id: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id === id);
    if (selectedRow) {
      setDados({ ...selectedRow });
      setOpenDialog(true);
      setShowSaveCancelButtons(false);
      setShowUpdateCancelButtons(true);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleShowClick = (id: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id === id);
    if (selectedRow) {
      setDados({ ...selectedRow });
      setOpenDialog(true);
      setShowSaveCancelButtons(true);
      setShowUpdateCancelButtons(false);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeOpen = (event) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      setDados({ ...dados, anexo: file });
    }
  };

  const handleEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CNH_ATT}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };

  const formatDateTime = (dateTimeStr: string) => {
    const [year, month, day] = dateTimeStr.split('-');
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome Motorista',
      width: 330,
      editable: false
    },
    {
      field: 'n_cnh',
      headerName: 'CNH',
      width: 110,
      editable: false
    },
    {
      field: 'n_cpf',
      headerName: 'CPF',
      width: 110,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'data_validade',
      headerName: 'Validade',
      width: 100,
      editable: false,
      renderCell: (params) => (
        <span>{params.value ? formatDateTime(params.value) : ''}</span>
      ),
    },
    {
      field: 'status_validade',
      headerName: 'Status',
      width: 90,
      editable: false,
    },
    {
      field: 'categoria',
      headerName: 'Categoria',
      width: 90,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <React.Fragment>
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="Show"
              onClick={handleShowClick(id)}
              color="inherit"
            />
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
            {showSaveCancelButtons && (
              <>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  PaperProps={{
                    component: 'form',
                  }}
                >
                  <DialogTitle>Dados CNH</DialogTitle>
                  <DialogContent>
                    <TextField
                      label="Nome"
                      id="nome"
                      name="nome"
                      value={dados.nome}
                      onChange={handleChangeOpen}
                      margin="normal"
                      fullWidth
                      style={{ marginRight: '20px' }}
                      InputProps={{ readOnly: true }}
                    />
                    <TextField
                      label="Número CNH"
                      id="n_cnh"
                      name="n_cnh"
                      value={dados.n_cnh}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                      InputProps={{ readOnly: true }}
                    />
                    <TextField
                      label="Número CPF"
                      id="n_cpf"
                      name="n_cpf"
                      value={dados.n_cpf}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                      InputProps={{ readOnly: true }}
                    />
                    <TextField
                      label="Validade"
                      id="data_validade"
                      name="data_validade"
                      type="date"
                      value={dados.data_validade}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                      InputProps={{ readOnly: true }}
                    />
                    <TextField
                      label="Categoria"
                      id="categoria"
                      name="categoria"
                      value={dados.categoria}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      InputProps={{ readOnly: true }}
                      style={{ marginRight: '20px' }}
                    />
                    <br></br>
                    <b>Anexo:</b>
                    <br></br>
                    <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Fechar</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            {showUpdateCancelButtons && (
              <>

                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  PaperProps={{
                    component: 'form',
                    onSubmit: handleEdit
                  }}
                >
                  <DialogTitle>Atualizar CNH</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Preencha as informações necessárias para atualizar dados de CNH
                    </DialogContentText>
                    <TextField
                      label="Número CNH"
                      id="n_cnh"
                      name="n_cnh"
                      value={dados.n_cnh}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Número CPF"
                      id="n_cpf"
                      name="n_cpf"
                      value={dados.n_cpf}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Validade"
                      id="data_validade"
                      name="data_validade"
                      type="date"
                      value={dados.data_validade}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      label="Categoria"
                      id="categoria"
                      name="categoria"
                      value={dados.categoria}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />

                    <br></br>
                    <b>Anexo:</b>
                    <br></br>
                    <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} />
                    <FormControl fullWidth>
                      <Input
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: 'file/*' }}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="file-upload">
                        <Button variant="contained" component="span" >
                          <AttachFileIcon></AttachFileIcon> {dados.nome_arquivo}
                        </Button>
                      </label>
                    </FormControl>
                  </DialogContent>


                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button onClick={handleCloseDialog} type="submit">Atualizar</Button>
                  </DialogActions>
                </Dialog>

              </>
            )}

          </React.Fragment>
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 600,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
      />
    </Box>
  );
}
