import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { Dialog } from '@mui/material';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';
import * as process from 'process';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileSaverComponent from './file_viewer.tsx'


const initialRows: GridRowsProp = [
  {
    id_chamado: '',
    colaborador: '',
    solicitante: '',
    tarefa: '',
    prazo: '',
    dataTime_abertura: '',
    descricao: '',
    anexo: null as File | null,
    anexo_finalize: null as File | null,
    nome_anexo_finalize: '',
    status: '',
    dataTime_aceite: '',
    descricao_finalizacao: ''
  },
];

export default function GridChamadoFinalized() {
  const [rows, setRows] = React.useState(initialRows);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState<Blob | null>(null);
  const [fileName, setFileName] = useState('');

  const [opcoesColab, setOpcoesColab] = useState([]);

  const [showSaveCancelButtons, setShowSaveCancelButtons] = React.useState(true);
  const [showUpdateCancelButtons, setShowUpdateCancelButtons] = React.useState(false);




  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0]; // Adiciona o operador de acesso seguro '?'
    if (uploadedFile instanceof File) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    }
  };

  const [dados, setDados] = React.useState({
    id_chamado: '',
    colaborador: '',
    solicitante: '',
    tarefa: '',
    prazo: '',
    dataTime_abertura: '',
    descricao: '',
    anexo: null as File | null,
    anexo_finalize: null as File | null,
    nome_anexo_finalize: '',
    status: '',
    dataTime_aceite: '',
    nome_arquivo: '',
    descricao_finalizacao: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CHAMADO_FINALIZED}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`
          }
        });
        setRows(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_USER_LIST}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`
          }
        });
        const data = response.data;
        setOpcoesColab(data.map((item) => ({ value: item.value, label: item.label })));


      } catch (error) {
        console.error('Erro ao buscar opções de colaboradores:', error);
      }
    };

    // Busca os dados inicialmente
    fetchData();

    // Configura o intervalo para buscar dados regularmente
    const interval = setInterval(fetchData, process.env.REACT_APP_INTERVAL_TIME); // A cada 5 segundos (5000 milissegundos)

    // Limpa o intervalo quando o componente é desmontado para evitar vazamento de memória
    return () => clearInterval(interval);
  }, []);

  const [openDialog, setOpenDialog] = React.useState(false);


  const handleShowClick = (id_chamado: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id_chamado === id_chamado);
    if (selectedRow) {
      setDados({ ...selectedRow });
      setOpenDialog(true);
      setShowSaveCancelButtons(true);
      setShowUpdateCancelButtons(false);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleFinalize = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CHAMADO_FINALIZE}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };


  const handleChangeOpen = (event) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };




  const columns: GridColDef[] = [
    {
      field: 'tarefa',
      headerName: 'Tarefa',
      width: 90,
      editable: false
    },
    {
      field: 'colaborador',
      headerName: 'Colaborador',
      width: 100,
      editable: false
    },
    {
      field: 'solicitante',
      headerName: 'Solicitante',
      width: 100,
      editable: false
    },
    {
      field: 'dataTime_abertura',
      headerName: 'Abertura',
      width: 150,
      editable: false
    },
    {
      field: 'prazo',
      headerName: 'Prazo',
      width: 150,
      editable: false
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      editable: false
    },
    {
      field: 'nome_arquivo',
      headerName: 'Anexo',
      width: 150,
      editable: false
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <React.Fragment>
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="Delete"
              onClick={handleShowClick(id)}
              color="inherit"
            />

            {showSaveCancelButtons && (
              <>
                <Dialog
                  open={openDialog}
                  onClose={handleClose}
                  PaperProps={{
                    component: 'form',
                    onSubmit: handleFinalize,
                  }}
                >
                  <DialogTitle>Dados Chamado</DialogTitle>
                  <DialogContent>
                    <Box border={0} borderColor="primary.main" padding={0.5} alignItems={'baseline'}>
                      <br></br>
                      <b>Dados:</b>
                      <br></br>
                      <TextField
                        label="Colaborador"
                        id="colaborador"
                        name="colaborador"
                        value={dados.colaborador}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ width: '40%', marginRight: '20px' }}
                        required
                        InputProps={{ readOnly: true }}
                      >
                      </TextField>

                      <TextField
                        label="Solicitante"
                        id="solicitante"
                        name="solicitante"
                        value={dados.solicitante}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ width: '40%', marginRight: '20px' }}
                        required
                        InputProps={{ readOnly: true }}
                      >
                      </TextField>

                      <TextField
                        select
                        label="Tarefa"
                        id="tarefa"
                        name="tarefa"
                        value={dados.tarefa}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ width: '40%', marginRight: '20px' }}
                        InputProps={{ readOnly: true }}
                        required
                      >
                      </TextField>

                      <TextField
                        label="Prazo Conclusão"
                        id="prazo"
                        name="prazo"
                        type="text"
                        value={dados.prazo}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Data Abertura"
                        id="dataTime_abertura"
                        name="dataTime_abertura"
                        type="text"
                        value={dados.dataTime_abertura}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                    </Box>

                    <TextField
                      label="Descrição"
                      id="descricao"
                      name="descricao"
                      value={dados.descricao}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '100%', marginRight: '20px' }}
                      multiline
                      rows={4}
                      InputProps={{ readOnly: true }}
                    />

<br></br>
                    <b>Anexo</b>
                    <br></br>

                    <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} />
                    <br></br>
                    <Divider></Divider>
                    <b>Finalização</b>
                    <br></br>

                    <TextField
                      label="Descrição Finalizacao"
                      id="descricao_finalizacao"
                      name="descricao_finalizacao"
                      value={dados.descricao_finalizacao}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '100%', marginRight: '20px' }}
                      multiline
                      rows={4}
                      InputProps={{ readOnly: true }}
                    />



                    <br></br>
                    <b>Anexo</b>
                    <br></br>
                    <FileSaverComponent hexData={dados.anexo_finalize} fileName={dados.nome_anexo_finalize} />


                  </DialogContent>

                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Fechar</Button>
                  </DialogActions>

                </Dialog>
              </>
            )}


          </React.Fragment>
        ];
      },
    },
  ];
  return (
    <Box
      sx={{
        height: 600,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id_chamado}
      />
    </Box>
  );
}
