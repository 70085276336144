import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Button } from '@mui/material';
import MenuLateral from '../components/menu.tsx';
import FormCadDriver from './../components/motorista/FormDialog_cadDriver.tsx'
import GridDriver from './../components/motorista/GridDriver.tsx';
import AppBar from '../components/Appbar.tsx';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function Page_cadDriver() {

  return (
    <Container maxWidth={'xl'} style={{}}>

      <Box>
        <AppBar></AppBar>


        <Grid container spacing={2} columns={16}>
          <Grid item xs={3}>
            <Item>
              <MenuLateral></MenuLateral>
            </Item>
          </Grid>

          <Grid item xs={13} sx={{ justifyContent: 'left' }}>
            <Item sx={{ justifyContent: 'left' }}>

              <Grid container spacing={2} columns={12}>
                <Grid item xs={10}>
                  {/* <FormFilter></FormFilter> */}
                  {/* <CardList></CardList> */}
                  <GridDriver></GridDriver>
                  {/* <GridTruck></GridTruck> */}



                </Grid>

                <Grid item xs={2}>
                  <Button variant='text' style={{ justifyContent: 'left' }}>
                    <FormCadDriver>
                    </FormCadDriver>


                  </Button>
                </Grid>
              </Grid>




            </Item>

          </Grid>
        </Grid>

      </Box>
    </Container>
  )
}
