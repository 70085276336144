import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ModeIcon from '@mui/icons-material/Mode';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PersonIcon from '@mui/icons-material/Person';
import PieChartIcon from '@mui/icons-material/PieChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import CodeIcon from '@mui/icons-material/Code';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ArticleIcon from '@mui/icons-material/Article';

export default function NestedList() {
  const [open, setOpen] = React.useState(true);
  const [openChamados, setOpenChamados] = React.useState(true);
  const [openDoc, setOpenDoc] = React.useState(true);

  const handleClickCad = () => {
    setOpen(!open);
  };

  const handleClickChamados = () => {
    setOpenChamados(!openChamados);
  };

  const handleClickCadDoc = () => {
    setOpenDoc(!openDoc);
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Menu
        </ListSubheader>
      }
    >
      <ListItemButton href='/dashboard'>
        <ListItemIcon>
          <PieChartIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>

      <ListItemButton onClick={handleClickChamados}>
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText primary="Chamados" />
        {openChamados ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openChamados} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton href='/chamadosabertos' sx={{ pl: 4 }}>
            <ListItemIcon>
              <PlaylistAddIcon />
            </ListItemIcon>
            <ListItemText primary="Abrir Chamado" />
          </ListItemButton>
          <ListItemButton href='/chamadosemandamento' sx={{ pl: 4 }}>
            <ListItemIcon>
              <IntegrationInstructionsIcon />
            </ListItemIcon>
            <ListItemText primary="Em andamento" />
          </ListItemButton>
          <ListItemButton href='/chamadosfinalizado' sx={{ pl: 4 }}>
            <ListItemIcon>
              <AssignmentReturnedIcon />
            </ListItemIcon>
            <ListItemText primary="Finalizado" />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton onClick={handleClickCad}>
        <ListItemIcon>
          <ModeIcon />
        </ListItemIcon>
        <ListItemText primary="Cadastro" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton href='/cadtruck' sx={{ pl: 4 }}>
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText primary="Carro" />
          </ListItemButton>
          <ListItemButton href='/caddriver' sx={{ pl: 4 }}>
            <ListItemIcon>
              <PermIdentityIcon />
            </ListItemIcon>
            <ListItemText primary="Motorista" />
          </ListItemButton>

          <ListItemButton onClick={handleClickCadDoc} sx={{ pl: 4 }}>
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary="Documentos" />
            {openDoc ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openDoc} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton href='/caddoccrlv' sx={{ pl: 8 }}>
                <ListItemIcon>
                  <DocumentScannerIcon />
                </ListItemIcon>
                <ListItemText primary="CRLV" />
              </ListItemButton>
              <ListItemButton href='/caddoccnh' sx={{ pl: 8 }}>
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="CNH" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Collapse>

      <ListItemButton href='/desenvolvedor'>
        <ListItemIcon>
          <CodeIcon />
        </ListItemIcon>
        <ListItemText primary="Desenvolvedor" />
      </ListItemButton>

      <ListItemButton href='/cad_users'>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Usuários" />
      </ListItemButton>
    </List>
  );
}
