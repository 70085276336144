import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Dialog } from '@mui/material';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import axios from 'axios';
import getIdToken from '../../../utils/api_loker.tsx';
import * as process from 'process';
import FileSaverComponent from './file_viewer.tsx';

const initialRows: GridRowsProp = [];


export default function GridCRLV() {
  const [rows, setRows] = React.useState(initialRows);
  const [showSaveCancelButtons, setShowSaveCancelButtons] = React.useState(true);
  const [showUpdateCancelButtons, setShowUpdateCancelButtons] = React.useState(false);


  const [dados, setDados] = React.useState({
    id: '',
    renavam: '',
    placa: '',
    uf: '',
    exercicio: '',
    municipio: '',
    chassi: '',
    tipo: '',
    modelo: '',
    combustivel: '',
    cor: '',
    ano: '',
    cod_CLA: '',
    n_CRV: '',
    data: '',
    descricao: '',
    nome_arquivo: '',
    anexo: null as File | null
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_LINK_CRLV}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`
          }
        });
        setRows(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    // Busca os dados inicialmente
    fetchData();

    // Configura o intervalo para buscar dados regularmente
    const interval = setInterval(fetchData, process.env.REACT_APP_INTERVAL_TIME); // A cada 5 segundos (5000 milissegundos)

    // Limpa o intervalo quando o componente é desmontado para evitar vazamento de memória
    return () => clearInterval(interval);
  }, []);

  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    if (openDialog) {
    }
  }, [openDialog]);


  const handleEditClick = (id: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id === id);
    if (selectedRow) {
      setDados({ ...selectedRow });
      setOpenDialog(true);
      setShowSaveCancelButtons(false);
      setShowUpdateCancelButtons(true);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleShowClick = (id: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id === id);
    if (selectedRow) {
      setDados({ ...selectedRow });
      setOpenDialog(true);
      setShowSaveCancelButtons(true);
      setShowUpdateCancelButtons(false);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeOpen = (event) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      setDados({ ...dados, anexo: file });
    }
  };


  const handleEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CRLV_ATT}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };


  const columns: GridColDef[] = [
    {
      field: 'placa',
      headerName: 'Placa',
      width: 110,
      editable: false
    },
    {
      field: 'ano',
      headerName: 'Ano',
      width: 70,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      width: 115,
      editable: false,
    },
    {
      field: 'cor',
      headerName: 'Cor',
      width: 115,
      editable: false,
    },
    {
      field: 'modelo',
      headerName: 'Modelo',
      width: 190,
      editable: false,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 110,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <React.Fragment>
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="Delete"
              onClick={handleShowClick(id)}
              color="inherit"
            />
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />



            {showSaveCancelButtons && (
              <>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  PaperProps={{
                    component: 'form',
                  }}
                >
                  <DialogTitle>Dados CRLV</DialogTitle>
                  <DialogContent>
                    <TextField
                      label="Placa"
                      id="placa"
                      name="placa"
                      value={dados.placa}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Reanam"
                      id="renavam"
                      name="renavam"
                      value={dados.renavam}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Chassi"
                      id="chassi"
                      name="chassi"
                      value={dados.chassi}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />

                    <TextField
                      label="Número CRV"
                      id="n_CRV"
                      name="n_CRV"
                      value={dados.n_CRV}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Ano"
                      id="ano"
                      name="ano"
                      value={dados.ano}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="UF"
                      id="uf"
                      name="uf"
                      value={dados.uf}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Município"
                      id="municipio"
                      name="municipio"
                      value={dados.municipio}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Cor"
                      id="cor"
                      name="cor"
                      value={dados.cor}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Exercício"
                      id="exercicio"
                      name="exercicio"
                      value={dados.exercicio}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Modelo"
                      id="modelo"
                      name="modelo"
                      value={dados.modelo}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Data"
                      id="data"
                      name="data"
                      type="text"
                      value={dados.data}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                      InputLabelProps={{ shrink: true }}

                    />
                    <TextField
                      label="Código CLA"
                      id="cod_CLA"
                      name="cod_CLA"
                      value={dados.cod_CLA}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Tipo"
                      id="tipo"
                      name="tipo"
                      value={dados.tipo}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Combustível"
                      id="combustivel"
                      name="combustivel"
                      value={dados.combustivel}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Descrição"
                      id="descricao"
                      name="descricao"
                      value={dados.descricao}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '100%', marginRight: '20px' }}
                      multiline
                      rows={4}
                    />
                    <br></br>
                    <b>Anexo:</b>
                    <br></br>
                    <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} />

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Fechar</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            {showUpdateCancelButtons && (
              <>



                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  PaperProps={{
                    component: 'form',
                    onSubmit: handleEdit
                  }}
                >
                  <DialogTitle>Atualizar CRLV</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Preencha as informações necessárias para atualizar CRLV
                    </DialogContentText>

                    <TextField
                      label="Placa"
                      id="placa"
                      name="placa"
                      value={dados.placa}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Reanam"
                      id="renavam"
                      name="renavam"
                      value={dados.renavam}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Chassi"
                      id="chassi"
                      name="chassi"
                      value={dados.chassi}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />

                    <TextField
                      label="Número CRV"
                      id="n_CRV"
                      name="n_CRV"
                      value={dados.n_CRV}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Ano"
                      id="ano"
                      name="ano"
                      value={dados.ano}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="UF"
                      id="uf"
                      name="uf"
                      value={dados.uf}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Município"
                      id="municipio"
                      name="municipio"
                      value={dados.municipio}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Cor"
                      id="cor"
                      name="cor"
                      value={dados.cor}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Exercício"
                      id="exercicio"
                      name="exercicio"
                      value={dados.exercicio}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Modelo"
                      id="modelo"
                      name="modelo"
                      value={dados.modelo}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Data"
                      id="data"
                      name="data"
                      type="text"
                      value={dados.data}
                      onChange={handleChangeOpen}
                      margin="normal"
                      required
                      style={{ marginRight: '20px' }}
                      InputLabelProps={{ shrink: true }}

                    />
                    <TextField
                      label="Código CLA"
                      id="cod_CLA"
                      name="cod_CLA"
                      value={dados.cod_CLA}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Tipo"
                      id="tipo"
                      name="tipo"
                      value={dados.tipo}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />
                    <TextField
                      label="Combustível"
                      id="combustivel"
                      name="combustivel"
                      value={dados.combustivel}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ marginRight: '20px' }}
                    />


                    <TextField
                      label="Descrição"
                      id="descricao"
                      name="descricao"
                      value={dados.descricao}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '100%', marginRight: '20px' }}
                      multiline
                      rows={4}
                    />

                    <br></br>
                    <b>Anexo:</b>
                    <br></br>
                    
                    <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} />
                    <br></br>
                    <FormControl fullWidth>
                      <Input
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: 'file/*' }}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="file-upload">
                        <Button variant="contained" component="span" >
                          <AttachFileIcon></AttachFileIcon> {dados.nome_arquivo}
                        </Button>
                      </label>
                    </FormControl>
         

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog}>Cancelar</Button>
                  <Button onClick={handleCloseDialog} type="submit">Atualizar</Button>
                </DialogActions>
              </Dialog>

          </>
            )
}



          </React.Fragment >
        ];
      },
    },
  ];
return (
  <Box
    sx={{
      height: 600,
      width: '100%',
      '& .actions': {
        color: 'text.secondary',
      },
      '& .textPrimary': {
        color: 'text.primary',
      },
    }}
  >
    <DataGrid
      rows={rows}
      columns={columns}
      getRowId={(row) => row.id}
    />
  </Box>
);
}
