import * as React from 'react';
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';

const aData = [4000, 3000, 2000, 2780, 1890, 2390, 3490, 2000, 2780, 1890, 1890];
// const bData = [3000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
  'jav',
  'fev',
  'mar',
  'abr',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez',
];

export default function SimpleAreaChart() {
  return (
    <LineChart
      width={500}
      height={200}
      series={[{ data: aData, area: true, showMark: false }]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      sx={{
        [`& .${lineElementClasses.root}`]: {
          display: 'none',
        },
      }}
    />
  );
}
