import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormFilter from '../components/FormFilter.tsx'
import { Container, Button } from '@mui/material';
import MenuLateral from '../components/menu.tsx';


import FormDialog_cad_CRLV from '../components/documentos/renavan/FormDialog_cad_CRLV.tsx';
import GridCRLV from '../components/documentos/renavan/GridCRLV.tsx';


import FormDialog_cad_CNH from '../components/documentos/cnh/FormDialog_cadCNH.tsx';
import GridCNH from '../components/documentos/cnh/GridCNH.tsx';



import AppBar from '../components/Appbar.tsx';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));






export default function Page_cadDoc_CNH() {

 
  return (
    <Container maxWidth={'xl'} style={{}}>
      <Box>
        <AppBar></AppBar>
        {/* <h6 style={{ color: 'white' }}>nada</h6> */}

        <Grid container spacing={2} columns={16}>
          <Grid item xs={3}>
            <Item>
              <MenuLateral></MenuLateral>
            </Item>
          </Grid>

          <Grid item xs={13} sx={{justifyContent: 'left'}}>
            <Item  sx={{justifyContent: 'left'}}>

              <Grid container spacing={2} columns={16}>
                <Grid item xs={13}>
                <GridCNH></GridCNH>
                </Grid>

                <Grid item xs={3}>
                <Button  variant='text' style={{justifyContent: 'left'}}>
                <FormDialog_cad_CNH></FormDialog_cad_CNH>
                  
                </Button>
                </Grid>
              </Grid>
              
               

            
            </Item>
          
          
          
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}