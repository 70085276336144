import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function BasicLabel() {
  return (
    <BarChart

      {...props}
      series={[
        {
          data: [24, 13, 40],
        //   label: 'label 1',
        },
      ]}
    />
  );
}

const props = {
  width: 500,
  height: 300,
  xAxis: [{ data: ['Abertos', 'Em Andamento', 'Finalizados'], scaleType: 'band' as const }],
};
