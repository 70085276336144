import { useEffect, useState } from 'react';

function ImagemMotorista({ imagemHex }) {
  const [imagemURL, setImagemURL] = useState(null);

  useEffect(() => {
    if (imagemHex) {
      const bytes = new Uint8Array(imagemHex.match(/[\da-f]{2}/gi).map((h) => parseInt(h, 16)));
      const blob = new Blob([bytes], { type: 'image/jpg' });
      const url = URL.createObjectURL(blob);
      setImagemURL(url);
    }
  }, [imagemHex]);

  return (
    <img
      src={imagemURL}
      alt="Imagem do motorista"
      style={{ width: '100%', height: 'auto' }}
    />
  );
}
export default ImagemMotorista