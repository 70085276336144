import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import MenuItem from '@mui/material/MenuItem';
import { InputAdornment } from '@mui/material';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Dialog } from '@mui/material';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';
import * as process from 'process';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR'; // Caso precise formatar em português do Brasil

import FileSaverComponent from './file_viewer.tsx'


const initialRows: GridRowsProp = [
  {
    id_chamado: '',
    erro: '',
    descricao: '',
    nome_arquivo: '',
    anexo: null as File | null,
    status: '',
  },
];

export default function GridChamadoDEV() {
  const [rows, setRows] = React.useState(initialRows);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState<Blob | null>(null);
  const [fileName, setFileName] = useState('');



  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0]; // Adiciona o operador de acesso seguro '?'
    if (uploadedFile instanceof File) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    }
  };

  const [dados, setDados] = React.useState({
    id_chamado: '',
    erro: '',
    descricao: '',
    nome_arquivo: '',
    anexo: null as File | null,
    status: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_LINK_DEV}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`
          }
        });
        setRows(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }

    };

    // Busca os dados inicialmente
    fetchData();

    // Configura o intervalo para buscar dados regularmente
    const interval = setInterval(fetchData, process.env.REACT_APP_INTERVAL_TIME); // A cada 5 segundos (5000 milissegundos)

    // Limpa o intervalo quando o componente é desmontado para evitar vazamento de memória
    return () => clearInterval(interval);
  }, []);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleEditClick = (id_chamado: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id_chamado === id_chamado);
    if (selectedRow) {
      setDados({
        id_chamado: selectedRow.id_chamado,
        erro: selectedRow.erro,
        descricao: selectedRow.descricao,
        nome_arquivo: selectedRow.nome_arquivo,
        anexo: selectedRow.anexo,
        status: selectedRow.status,
      });
      setOpenDialog(true);
      setShowSaveCancelButtons(false);
      setShowUpdateCancelButtons(true);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleShowClick = (id_chamado: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id_chamado === id_chamado);
    if (selectedRow) {
      setDados({
        id_chamado: selectedRow.id_chamado,
        erro: selectedRow.erro,
        descricao: selectedRow.descricao,
        nome_arquivo: selectedRow.nome_arquivo,
        anexo: selectedRow.anexo,
        status: selectedRow.status,
      });
      setOpenDialog(true);
      setShowSaveCancelButtons(true);
      setShowUpdateCancelButtons(false);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleAcept = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CHAMADO_ACEPT}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };


  const handleEdit= async (event: React.FormEvent<HTMLFormElement>) => {
    // setDados({ ...dados, status: 'Em andamento' });
    // const currentDate = new Date();
    // const formattedDate = currentDate.toISOString().slice(0, 16);
    // // setDados(prevDados => ({ ...prevDados, dataTime_aceite: currentDate }));
    // setDados({ ...dados, dataTime_aceite: formattedDate });
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CHAMADO_ACEPT}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };


  const handleChangeOpen = (event) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };




  const columns: GridColDef[] = [
    {
      field: 'id_chamado',
      headerName: 'ID',
      width: 90,
      editable: false
    },
    {
      field: 'erro',
      headerName: 'Erro',
      width: 100,
      editable: false
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 150,
      editable: false
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      editable: false
    },
    {
      field: 'nome_arquivo',
      headerName: 'Anexo',
      width: 300,
      editable: false
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <React.Fragment>
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="Delete"
              onClick={handleShowClick(id)}
              color="inherit"
            />
                <Dialog
                  open={openDialog}
                  onClose={handleClose}
                  PaperProps={{
                    component: 'form',
                    // onSubmit: handleAcept,
                  }}
                >
                  <DialogTitle>Dados Chamado</DialogTitle>
                  <DialogContent>
                  <br></br>
          <b>Dados:</b>
          <br></br>
          <TextField
            label="Erro"
            id="erro"
            name="erro"
            value={dados.erro}
            onChange={handleChangeOpen}
            margin="normal"
          />

          <TextField
            label="Status"
            id="status"
            name="status"
            value={dados.status}
            onChange={handleChangeOpen}
            margin="normal"
          />



          <TextField
            label="Descrição"
            id="descricao"
            name="descricao"
            value={dados.descricao}
            onChange={handleChangeOpen}
            margin="normal"
            style={{ width: '100%', marginRight: '20px' }}
            multiline
            rows={4}
          />
                    <br></br>
                    <b>Anexo:</b>
                    <br></br>

                    <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} />
                   

                  </DialogContent>

                  <DialogActions>
                  {/* <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} /> */}
                    <Button onClick={handleCloseDialog}>Fechar</Button>
                    {/* <Button onClick={handleCloseDialog} type="submit">Aceitar</Button> */}
                  </DialogActions>

                </Dialog>
 
        
            
          </React.Fragment>
        ];
      },
    },
  ];
  return (
    <Box
      sx={{
        height: 600,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id_chamado}
      />
    </Box>
  );
}
