import './App.css';
import AppBar from './components/Appbar.tsx';
import Home from './screens/home.tsx';


function App() {
  return (
    <div className="App">
      <AppBar>  </AppBar>
      <Home> </Home>
    </div>
  );
}

export default App;


