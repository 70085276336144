import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import OutlinedCard from '../components/homepage/Card.tsx'
import MultiActionAreaCard from '../components/homepage/CardImage.tsx';
import ImageAvatars from '../components/homepage/AvatarLImage.tsx';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ResponsiveGrid() {
  return (
    <Container maxWidth={'xl'}>
      <Box>

        <Box sx={{ color: 'black', background: '', boxShadow: '-moz-initial' }}>

          <h1>
            Bem-vindo à página da MB TRANSCONSULTORIA
          </h1>
          <h2 style={{ color: 'black' }}>
            Uma empresa dedicada para fornecer o melhor trabalho
          </h2>
          <h2 style={{ color: 'white' }}>espacamento</h2>
        </Box>


        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={7}
              alignContent={'center'}
              alignItems={'center'}
              alignSelf={'center'}
              textAlign={'center'}
              justifyContent={'center'}
              sx={{
                // maxWidth: '600px', // Definindo um tamanho máximo
                overflow: 'hidden' // Adicionando overflow para controlar o comportamento de transbordamento
              }}>
              <Grid container spacing={2} minHeight={160}>
                <Grid xs display="flex" justifyContent="center" alignItems="center">
                  <OutlinedCard></OutlinedCard>
                </Grid>

                <Grid display="flex" justifyContent="center" alignItems="center">
                  <OutlinedCard></OutlinedCard>
                </Grid>
              </Grid>
            </Grid>


            <Grid
              item xs={6}
              md={5}
              alignContent={'center'}
              alignItems={'center'}
              alignSelf={'center'}
              textAlign={'center'}
              justifyContent={'center'}
              sx={{
                overflow: 'hidden',
              }}
            >
              <Item 
                sx={{
                  alignItems: 'center',
                  alignContent: 'center'
                }}
              >
                <h1>
                  Conheça nossa equipe:
                </h1>
                <ImageAvatars></ImageAvatars>
              </Item>
            </Grid>


            <Grid item xs={6} md={6}>
              <Item style={{ background: 'LightSkyBlue' }}>
                <h1 >
                  Parceiros:
                </h1>
                <h6 style={{ color: 'LightSkyBlue' }}>espacamento</h6>


                <Grid container spacing={2} minHeight={160} style={{ background: 'LightSkyBlue' }}>

                  <Grid xs display="flex" justifyContent="center" alignItems="center">
                    <MultiActionAreaCard></MultiActionAreaCard>
                  </Grid>
                  <Grid xs display="flex" justifyContent="center" alignItems="center">
                    <MultiActionAreaCard></MultiActionAreaCard>
                  </Grid>
    
                  


                </Grid>
              </Item>
            </Grid>

            <Grid item xs={6} md={6}>
         
            </Grid>

          </Grid>
        </Box>

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={8}>
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
          </Grid>
        </Grid>
      </Box>
    </Container>

  )
}
