import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputMask from 'react-input-mask';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { InputAdornment } from '@mui/material';
import { useState } from 'react';
import * as process from 'process';
import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';



export default function FormCadDriver() {
  const [open, setOpen] = React.useState(false);
  const [dados, setDados] = useState({
    nome: '',
    nascimento: '',
    telefone: '',
    ncpf: '',
    nrg: '',
    ncnh: '',
    salario: '',
    rua: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: '',
    numero_casa: '',
    situacao: '',
    observacao: '',
    agregado: '',
    imagem: null as File | null,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      setDados({ ...dados, imagem: file });
    }
  };

  const handleCEPBlur = async () => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${dados.cep}/json/`);
      const endereco = await response.json();
      setDados({
        ...dados,
        rua: endereco.logradouro,
        bairro: endereco.bairro,
        cidade: endereco.localidade,
        uf: endereco.uf,
      });
    } catch (error) {
      console.error('Erro ao consultar o CEP:', error);
    }
  };


  const handleClickOpen = (event) => {
    setDados({
      nome: '',
      nascimento: '',
      telefone: '',
      ncpf: '',
      nrg: '',
      ncnh: '',
      salario: '',
      rua: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      cep: '',
      situacao: '',
      observacao: '',
      numero_casa: '',
      agregado: '',
      imagem: null as File | null,
    });
    setOpen(true);
    // setFileName('');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const opcoesSituacao = [
    {
      value: 'Ativo',
      label: 'Ativo',
    },
    {
      value: 'Inativo',
      label: 'Inativo',
    },
  ];

  const opcoesAgregado = [
    {
      value: 'Sim',
      label: 'Sim',
    },
    {
      value: 'Não',
      label: 'Não',
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CAD_DRIVER_NEW_REGISTER}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };


  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} style={{}}>
        Novo Motorista
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Novo Motorista</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha as informações necessárias para cadastrar um novo motorista
          </DialogContentText>
          <Box border={0} borderColor="primary.main" padding={0.5} alignItems={'baseline'}>
            <br></br>
            <b>Dados:</b>
            <br></br>
            <TextField
              label="Nome Completo"
              id="nome"
              name="nome"
              value={dados.nome}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Data nascimento"
              id="nascimento"
              name="nascimento"
              type="date"
              value={dados.nascimento}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
              InputLabelProps={{ shrink: true }}
            />
            <InputMask
              mask="(99) 99999-9999"
              value={dados.telefone}
              onChange={handleChange}
              maskChar=" "
            >
              {() => (
                <TextField
                  label="Telefone"
                  id="telefone"
                  name="telefone"
                  margin="normal"
                  style={{ marginRight: '20px' }}
                />
              )}
            </InputMask>
            <InputMask
              mask="999.999.999-99"
              value={dados.ncpf}
              onChange={handleChange}
              maskChar=" "
            >
              {() => (
                <TextField
                  label="CPF"
                  id="ncpf"
                  name="ncpf"
                  margin="normal"
                  style={{ marginRight: '20px' }}
                />
              )}
            </InputMask>
            <InputMask
              mask="99.999.999-9"
              value={dados.nrg}
              onChange={handleChange}
              maskChar=" "
            >
              {() => (
                <TextField
                  label="RG"
                  id="nrg"
                  name="nrg"
                  margin="normal"
                  style={{ marginRight: '20px' }}
                />
              )}
            </InputMask>
            <TextField
              label="Numero CNH"
              id="ncnh"
              name="ncnh"
              value={dados.ncnh}
              onChange={handleChange}
              margin="normal"
              style={{ marginRight: '20px' }}
            />
          </Box>

          <br></br>
          <b>Endereço:</b>
          <br></br>
          <TextField
            label="CEP"
            id="cep"
            name="cep"
            value={dados.cep}
            onChange={handleChange}
            onBlur={handleCEPBlur}
            margin="normal"
            style={{ marginRight: '20px' }}
            required
          />
          <TextField
            label="Rua"
            id="rua"
            name="rua"
            value={dados.rua}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
            required
            disabled
          />
          <TextField
            label="Bairro"
            id="bairro"
            name="bairro"
            value={dados.bairro}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
            required
            disabled
          />
          <TextField
            label="Cidade"
            id="cidade"
            name="cidade"
            value={dados.cidade}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
            required
            disabled
          />
          <TextField
            label="UF"
            id="uf"
            name="uf"
            value={dados.uf}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
            required
            disabled
          />
          <TextField
            label="Complemento"
            id="complemento"
            name="complemento"
            value={dados.complemento}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
            required
          />
          <TextField
            label="Número"
            id="numero_casa"
            name="numero_casa"
            value={dados.numero_casa}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
            required
          />

          <br></br>
          <b>Informações:</b>
          <br></br>
          <TextField
            select
            label="Situação"
            id="situacao"
            name="situacao"
            value={dados.situacao}
            onChange={handleChange}
            margin="normal"
            style={{ width: '40%', marginRight: '20px' }}
            required
          >
            {opcoesSituacao.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Agregado?"
            id="agregado"
            name="agregado"
            value={dados.agregado}
            onChange={handleChange}
            margin="normal"
            style={{ width: '40%', marginRight: '20px' }}
            required
          >
            {opcoesAgregado.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Salário"
            id="salario"
            name="salario"
            value={dados.salario}
            onChange={handleChange}
            margin="normal"
            style={{ marginRight: '20px' }}
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
          />

          <br></br>
          <b>Observações:</b>
          <br></br>
          <TextField
            label="Observações"
            id="observacao"
            name="observacao"
            value={dados.observacao}
            onChange={handleChange}
            margin="normal"
            style={{ width: '100%', marginRight: '20px' }}
            multiline
            rows={4}
          />

          <FormControl fullWidth>
            <Input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: 'image/*' }}
              style={{ display: 'none' }}
            />
            <label htmlFor="file-upload">
              <Button variant="contained" component="span" fullWidth>
                Foto motorista: {dados.imagem ? dados.imagem.name : 'Selecione uma imagem'}
              </Button>
            </label>
          </FormControl>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClose} type="submit">Cadastrar</Button>
        </DialogActions>

      </Dialog>
    </React.Fragment>
  );
}
