import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputMask from 'react-input-mask';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { InputAdornment } from '@mui/material';

import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';
import * as process from 'process';




export default function FormCadChamadoDEV() {
  const [open, setOpen] = useState(false);
  const [opcoesColab, setOpcoesColab] = useState([]);
  const [dados, setDados] = useState({
    erro: '',
    descricao: '',
    anexo: null as File | null,
    status: '',
  });

  useEffect(() => {
    // Obter a data e hora atuais e formatá-las como YYYY-MM-DDTHH:MM
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 16);
    setDados(prevDados => ({ ...prevDados, dataTime_abertura: formattedDate }));
  }, []);


  const handleClickOpen = (event) => {
    setDados({
      erro: '',
      descricao: '',
      anexo: null as File | null,
      status: '',
    });
    setOpen(true);
    // setFileName('');
  };


  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      setDados({ ...dados, anexo: file });
    }
  };


  const handleClose = () => {
    setOpen(false);
  };


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CAD_DEV}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };






  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} style={{}}>
        Novo Chamado
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Novo Chamado</DialogTitle>
        <DialogContent>

          <br></br>
          <b>Dados:</b>
          <br></br>
          <TextField
            label="Erro"
            id="erro"
            name="erro"
            value={dados.erro}
            onChange={handleChange}
            margin="normal"
          />

          <TextField
            label="Status"
            id="status"
            name="status"
            value={dados.status}
            onChange={handleChange}
            margin="normal"
          />



          <TextField
            label="Descrição"
            id="descricao"
            name="descricao"
            value={dados.descricao}
            onChange={handleChange}
            margin="normal"
            style={{ width: '100%', marginRight: '20px' }}
            multiline
            rows={4}
          />

          <br></br>
          <b>Anexo:</b>
          <br></br>
          <FormControl fullWidth>
            <Input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: 'file/*' }}
              style={{ display: 'none' }}
            />
            <label htmlFor="file-upload">
              <Button variant="contained" component="span" >
                <AttachFileIcon></AttachFileIcon> {dados.anexo ? dados.anexo.name : 'Selecione um arquivo'}
              </Button>
            </label>
          </FormControl>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClose} type="submit">Cadastrar</Button>
        </DialogActions>

      </Dialog>
    </React.Fragment>
  );
}
