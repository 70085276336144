// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import EditIcon from '@mui/icons-material/Edit';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import TextField from '@mui/material/TextField';
// import { Dialog } from '@mui/material';
// import {
//   GridRowsProp,
//   DataGrid,
//   GridColDef,
//   GridActionsCellItem,
//   GridRowId,
// } from '@mui/x-data-grid';
// import { useEffect } from 'react';
// import axios from 'axios';
// import getIdToken from '../../utils/api_loker.tsx';
// import * as process from 'process';

// const initialRows: GridRowsProp = [
//   {
//     id_truck: '',
//     renavam: '',
//     placa: '',
//     ano: '',
//     capacidadem3: '',
//     cor: '',
//     descricao: '',
//     modelo: '',
//     proprietario: '',
//     tipo: '',
//     transportadora: ''
//   },
// ];


// export default function GridTruck() {
//   const [rows, setRows] = React.useState(initialRows);
//   // const [open, setOpen] = React.useState(false);

//   const [showSaveCancelButtons, setShowSaveCancelButtons] = React.useState(true);
//   const [showUpdateCancelButtons, setShowUpdateCancelButtons] = React.useState(false);


//   const [dados, setDados] = React.useState({
//     id_truck: '',
//     renavam: '',
//     placa: '',
//     ano: '',
//     capacidadem3: '',
//     cor: '',
//     descricao: '',
//     modelo: '',
//     proprietario: '',
//     tipo: '',
//     transportadora: ''
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_LINK_TRUCK}`,{
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${await getIdToken()}`
//           }
//       });
//         setRows(response.data);
//       } catch (error) {
//         console.error('Erro ao buscar dados:', error);
//       }
//     };

//     // Busca os dados inicialmente
//     fetchData();

//     // Configura o intervalo para buscar dados regularmente
//     const interval = setInterval(fetchData, process.env.REACT_APP_INTERVAL_TIME); // A cada 5 segundos (5000 milissegundos)

//     // Limpa o intervalo quando o componente é desmontado para evitar vazamento de memória
//     return () => clearInterval(interval);
//   }, []);

//   const [openDialog, setOpenDialog] = React.useState(false);

//   useEffect(() => {
//     if (openDialog) {
//     }
//   }, [openDialog]);


//   const handleEditClick = (id_truck: GridRowId) => () => {
//     const selectedRow = rows.find(row => row.id_truck === id_truck);
//     if (selectedRow) {
//       setDados({
//         id_truck: selectedRow.id_truck,
//         renavam: selectedRow.renavam,
//         placa: selectedRow.placa,
//         ano: selectedRow.ano,
//         capacidadem3: selectedRow.capacidadem3,
//         cor: selectedRow.cor,
//         descricao: selectedRow.descricao,
//         modelo: selectedRow.modelo,
//         proprietario: selectedRow.proprietario,
//         tipo: selectedRow.tipo,
//         transportadora: selectedRow.transportadora
//       });
//       setOpenDialog(true);
//       setShowSaveCancelButtons(false);
//       setShowUpdateCancelButtons(true);
//     } else {
//       console.log('Linha não encontrada para este ID');
//     }
//   };

//   const handleShowClick = (id_truck: GridRowId) => () => {
//     const selectedRow = rows.find(row => row.id_truck === id_truck);
//     if (selectedRow) {
//       setDados({
//         id_truck: selectedRow.id_truck,
//         renavam: selectedRow.renavam,
//         placa: selectedRow.placa,
//         ano: selectedRow.ano,
//         capacidadem3: selectedRow.capacidadem3,
//         cor: selectedRow.cor,
//         descricao: selectedRow.descricao,
//         modelo: selectedRow.modelo,
//         proprietario: selectedRow.proprietario,
//         tipo: selectedRow.tipo,
//         transportadora: selectedRow.transportadora
//       });
//       setOpenDialog(true);
//       setShowSaveCancelButtons(true);
//       setShowUpdateCancelButtons(false);
//     } else {
//       console.log('Linha não encontrada para este ID');
//     }
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleChangeOpen = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
//     const { name, value } = event.target;
//     setDados({ ...dados, [name]: value });
//   };

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     const formData = new FormData();
//     Object.keys(dados).forEach((key) => {
//       formData.append(key, dados[key]);
//     });

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_CAD_TRUCK_ATUALIZE}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
//         }
//       });
//       // console.log(response.data); // Resposta do servidor (opcional)
//     } catch (error) {
//       console.error('Erro ao enviar formulário:', error);
//     }
//   };


//   const columns: GridColDef[] = [
//     {
//       field: 'placa',
//       headerName: 'Placa',
//       width: 110,
//       editable: false
//     },
//     {
//       field: 'ano',
//       headerName: 'Ano',
//       width: 70,
//       align: 'left',
//       headerAlign: 'left',
//       editable: false,
//     },
//     {
//       field: 'tipo',
//       headerName: 'Tipo',
//       width: 115,
//       editable: false,
//     },
//     {
//       field: 'capacidadem3',
//       headerName: 'Capacidade M3',
//       width: 115,
//       editable: false,
//     },
//     {
//       field: 'cor',
//       headerName: 'Cor',
//       width: 115,
//       editable: false,
//     },
//     {
//       field: 'modelo',
//       headerName: 'Modelo',
//       width: 190,
//       editable: false,
//     },
//     {
//       field: 'descricao',
//       headerName: 'Descrição',
//       width: 110,
//       editable: false,
//     },
//     {
//       field: 'actions',
//       type: 'actions',
//       headerName: 'Actions',
//       width: 100,
//       cellClassName: 'actions',
//       getActions: ({ id }) => {
//         return [
//       <React.Fragment>
//           <GridActionsCellItem
//             icon={<VisibilityIcon />}
//             label="Delete"
//             onClick={handleShowClick(id)}
//             color="inherit"
//           />
//         <GridActionsCellItem
//           icon={<EditIcon />}
//           label="Edit"
//           className="textPrimary"
//           onClick={handleEditClick(id)}
//           color="inherit"
//         />

//       <Dialog 
//       open={openDialog} 
//       onClose={handleCloseDialog}
//       PaperProps={{
//         component: 'form',
//         onSubmit: handleSubmit
//       }}
//       >
//       <DialogTitle>Novo Carro</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Preencha as informações necessárias para cadastrar um novo carro
//           </DialogContentText>

//           <TextField
//             label="Placa"
//             id="placa"
//             name="placa"
//             value={dados.placa}
//             onChange={handleChangeOpen}
//             margin="normal"
//             required
//             style={{ marginRight: '20px' }}
//           />
//           <TextField
//             label="Ano"
//             id="ano"
//             name="ano"
//             value={dados.ano}
//             onChange={handleChangeOpen}
//             margin="normal"
//             required
//             style={{ marginRight: '20px' }}
//           />
//           <TextField
//             label="Capacidade"
//             id="capacidadem3"
//             name="capacidadem3"
//             value={dados.capacidadem3}
//             onChange={handleChangeOpen}
//             margin="normal"
//             style={{ marginRight: '20px' }}
//           />
//           <TextField
//             label="Cor"
//             id="cor"
//             name="cor"
//             value={dados.cor}
//             onChange={handleChangeOpen}
//             margin="normal"
//             style={{ marginRight: '20px' }}
//           />
//           <TextField
//             label="Descrição"
//             id="descricao"
//             name="descricao"
//             value={dados.descricao}
//             onChange={handleChangeOpen}
//             margin="normal"
//             style={{ marginRight: '20px' }}
//           />
//           <TextField
//             label="Modelo"
//             id="modelo"
//             name="modelo"
//             value={dados.modelo}
//             onChange={handleChangeOpen}
//             margin="normal"
//             style={{ marginRight: '20px' }}
//           />
//           <TextField
//             label="Proprietario"
//             id="proprietario"
//             name="proprietario"
//             value={dados.proprietario}
//             onChange={handleChangeOpen}
//             margin="normal"
//             style={{ marginRight: '20px' }}
//           />
//           <TextField
//             label="Tipo"
//             id="tipo"
//             name="tipo"
//             value={dados.tipo}
//             onChange={handleChangeOpen}
//             margin="normal"
//             style={{ marginRight: '20px' }}
//           />
//           <TextField
//             label="Transportadora"
//             id="transportadora"
//             name="transportadora"
//             value={dados.transportadora}
//             onChange={handleChangeOpen}
//             margin="normal"
//             style={{ marginRight: '20px' }}
//           />
//         </DialogContent>
//         <DialogActions>
//           {showSaveCancelButtons && (
//             <>
//               <Button onClick={handleCloseDialog}>Fechar</Button>
//               {/* <Button onClick={handleCloseDialog} type="submit">Salvar</Button> */}
//             </>
//           )}
//           {showUpdateCancelButtons && (
//             <>
//               <Button onClick={handleCloseDialog}>Cancelar</Button>
//               <Button onClick={handleCloseDialog} type="submit">Atualizar</Button>
//             </>
//           )}
//         </DialogActions> 
//       </Dialog>
//       </React.Fragment>
//         ];
//       },
//     },
//   ];
//   return (
//     <Box
//       sx={{
//         height: 600,
//         width: '100%',
//         '& .actions': {
//           color: 'text.secondary',
//         },
//         '& .textPrimary': {
//           color: 'text.primary',
//         },
//       }}
//     >
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         getRowId={(row) => row.id_truck}
//       />
//     </Box>
//   );
// }


import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { GridRowsProp, DataGrid, GridColDef, GridActionsCellItem, GridRowId } from '@mui/x-data-grid';
import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';

const initialRows: GridRowsProp = [];

const EditDialog = ({ open, handleClose, dados, handleChange, handleSubmit, showSaveCancelButtons }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    PaperProps={{
      component: 'form',
      onSubmit: handleSubmit
    }}
  >
    <DialogTitle>Editar Carro</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Preencha as informações necessárias para editar o carro
      </DialogContentText>

      <TextField
        label="Placa"
        id="placa"
        name="placa"
        value={dados.placa}
        onChange={handleChange}
        margin="normal"
        fullWidth
        required
      />
      <TextField
        label="renavam"
        id="renavam"
        name="renavam"
        value={dados.renavam}
        onChange={handleChange}
        margin="normal"
        fullWidth
        required
      />
      <TextField
        label="Ano"
        id="ano"
        name="ano"
        value={dados.ano}
        onChange={handleChange}
        margin="normal"
        fullWidth
        required
      />
      <TextField
        label="Capacidade"
        id="capacidadem3"
        name="capacidadem3"
        value={dados.capacidadem3}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Cor"
        id="cor"
        name="cor"
        value={dados.cor}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Descrição"
        id="descricao"
        name="descricao"
        value={dados.descricao}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Modelo"
        id="modelo"
        name="modelo"
        value={dados.modelo}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Status"
        id="status"
        name="status"
        value={dados.status}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Vínculo"
        id="vinculo"
        name="vinculo"
        value={dados.vinculo}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Proprietário"
        id="proprietario"
        name="proprietario"
        value={dados.proprietario}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Tipo"
        id="tipo"
        name="tipo"
        value={dados.tipo}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        label="Transportadora"
        id="transportadora"
        name="transportadora"
        value={dados.transportadora}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      {showSaveCancelButtons && (
        <>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit">Salvar</Button>
        </>
      )}
    </DialogActions>
  </Dialog>
);

export default function GridTruck() {
  const [rows, setRows] = React.useState(initialRows);
  const [dados, setDados] = React.useState({
    id_truck: '',
    renavam: '',
    placa: '',
    ano: '',
    capacidadem3: '',
    cor: '',
    descricao: '',
    modelo: '',
    proprietario: '',
    tipo: '',
    transportadora: '',
    status: '',
    vinculo: ''
  });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogMode, setDialogMode] = React.useState('edit'); // 'edit' or 'show'

  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_LINK_TRUCK, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getIdToken()}`
        }
      });
      setRows(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, parseInt(process.env.REACT_APP_INTERVAL_TIME, 10) || 5000);
    return () => clearInterval(interval);
  }, []);

  const handleEditClick = (id_truck) => () => {
    const selectedRow = rows.find(row => row.id_truck === id_truck);
    if (selectedRow) {
      setDados({ ...selectedRow });
      setOpenDialog(true);
      setDialogMode('edit');
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleShowClick = (id_truck) => () => {
    const selectedRow = rows.find(row => row.id_truck === id_truck);
    if (selectedRow) {
      setDados({ ...selectedRow });
      setOpenDialog(true);
      setDialogMode('show');
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDados(prevDados => ({
      ...prevDados,
      [name]: value
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CAD_TRUCK_ATUALIZE}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    } finally {
      setOpenDialog(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'placa', headerName: 'Placa', width: 110 },
    { field: 'ano', headerName: 'Ano', width: 70 },
    { field: 'tipo', headerName: 'Tipo', width: 115 },
    { field: 'capacidadem3', headerName: 'Capacidade M3', width: 115 },
    { field: 'cor', headerName: 'Cor', width: 115 },
    { field: 'modelo', headerName: 'Modelo', width: 190 },
    { field: 'descricao', headerName: 'Descrição', width: 110 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 100,
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label="Mostrar"
          onClick={handleShowClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Editar"
          onClick={handleEditClick(id)}
          color="inherit"
        />
      ]
    }
  ];

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id_truck}
      />
      <EditDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        dados={dados}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        showSaveCancelButtons={dialogMode === 'edit'}
      />
    </Box>
  );
}
