import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Dialog } from '@mui/material';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';
import * as process from 'process';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileSaverComponent from './file_viewer.tsx'


const initialRows: GridRowsProp = [
  {
    id_chamado: '',
    colaborador: '',
    solicitante: '',
    tarefa: '',
    prazo: '',
    dataTime_abertura: '',
    descricao: '',
    anexo: null as File | null,
    anexo_finalize: null as File | null,
    status: '',
    dataTime_aceite: '',
    descricao_finalizacao: ''
  },
];

export default function GridChamadoEmAndamento() {
  const [rows, setRows] = React.useState(initialRows);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState<Blob | null>(null);
  const [fileName, setFileName] = useState('');

  const [opcoesColab, setOpcoesColab] = useState([]);

  const [showSaveCancelButtons, setShowSaveCancelButtons] = React.useState(true);
  const [showUpdateCancelButtons, setShowUpdateCancelButtons] = React.useState(false);



  const [dados, setDados] = React.useState({
    id_chamado: '',
    colaborador: '',
    solicitante: '',
    tarefa: '',
    prazo: '',
    dataTime_abertura: '',
    descricao: '',
    anexo: null as File | null,
    anexo_finalize: null as File | null,
    status: '',
    dataTime_aceite: '',
    nome_arquivo: '',
    descricao_finalizacao: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CHAMADO_PROCESS}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`
          }
        });
        setRows(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_USER_LIST}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`
          }
        });
        const data = response.data;
        setOpcoesColab(data.map((item) => ({ value: item.value, label: item.label })));


      } catch (error) {
        console.error('Erro ao buscar opções de colaboradores:', error);
      }
    };

    // Busca os dados inicialmente
    fetchData();

    // Configura o intervalo para buscar dados regularmente
    const interval = setInterval(fetchData, process.env.REACT_APP_INTERVAL_TIME); // A cada 5 segundos (5000 milissegundos)

    // Limpa o intervalo quando o componente é desmontado para evitar vazamento de memória
    return () => clearInterval(interval);
  }, []);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleEditClick = (id_chamado: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id_chamado === id_chamado);
    if (selectedRow) {
      setDados({ ...selectedRow });
      setOpenDialog(true);
      setShowSaveCancelButtons(false);
      setShowUpdateCancelButtons(true);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleShowClick = (id_chamado: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id_chamado === id_chamado);
    if (selectedRow) {
      setDados({ ...selectedRow });
      setOpenDialog(true);
      setShowSaveCancelButtons(true);
      setShowUpdateCancelButtons(false);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleFinalize = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CHAMADO_FINALIZE}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };


  const handleChangeOpen = (event) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      setDados({ ...dados, anexo_finalize: file });
    }
  };

  const formatDateTime = (dateTimeStr: string) => {
    const date = new Date(dateTimeStr);
    const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    return formattedDate;
  };




  const columns: GridColDef[] = [
    {
      field: 'tarefa',
      headerName: 'Tarefa',
      width: 90,
      editable: false
    },
    {
      field: 'colaborador',
      headerName: 'Colaborador',
      width: 100,
      editable: false
    },
    {
      field: 'solicitante',
      headerName: 'Solicitante',
      width: 100,
      editable: false
    },
    {
      field: 'dataTime_abertura',
      headerName: 'Abertura',
      width: 150,
      editable: false,
      renderCell: (params) => (
        <span>{params.value ? formatDateTime(params.value) : ''}</span>
      ),
    },
    {
      field: 'prazo',
      headerName: 'Prazo',
      width: 150,
      editable: false,
      renderCell: (params) => (
        <span>{params.value ? formatDateTime(params.value) : ''}</span>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      editable: false
    },
    {
      field: 'nome_arquivo',
      headerName: 'Anexo',
      width: 150,
      editable: false
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <React.Fragment>
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="Delete"
              onClick={handleShowClick(id)}
              color="inherit"
            />
            <GridActionsCellItem
              icon={<AssignmentTurnedInIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />



            {showSaveCancelButtons && (
              <>
                <Dialog
                  open={openDialog}
                  onClose={handleClose}
                  PaperProps={{
                    component: 'form',
                  }}
                >
                  <DialogTitle>Dados Chamado</DialogTitle>
                  <DialogContent>
                    <Box border={0} borderColor="primary.main" padding={0.5} alignItems={'baseline'}>
                      <br></br>
                      <b>Dados:</b>
                      <br></br>
                      <TextField
                        label="Colaborador"
                        id="colaborador"
                        name="colaborador"
                        value={dados.colaborador}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ width: '40%', marginRight: '20px' }}
                        required
                        InputProps={{ readOnly: true }}
                      >
                      </TextField>


                      <TextField
                        label="Solicitante"
                        id="solicitante"
                        name="solicitante"
                        value={dados.solicitante}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ width: '40%', marginRight: '20px' }}
                        required
                        InputProps={{ readOnly: true }}
                      >
                      </TextField>

                      <TextField
                        label="Tarefa"
                        id="tarefa"
                        name="tarefa"
                        value={dados.tarefa}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ width: '40%', marginRight: '20px' }}
                        InputProps={{ readOnly: true }}
                        required
                      >
                      </TextField>

                      <TextField
                        label="Prazo Conclusão"
                        id="prazo"
                        name="prazo"
                        type="datetime-local"
                        value={dados.prazo}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Data Abertura"
                        id="dataTime_abertura"
                        name="dataTime_abertura"
                        type="datetime-local"
                        value={dados.dataTime_abertura}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Data Aceite"
                        id="dataTime_aceite"
                        name="dataTime_aceite"
                        type="datetime-local"
                        value={dados.dataTime_aceite}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                    </Box>

                    <TextField
                      label="Descrição"
                      id="descricao"
                      name="descricao"
                      value={dados.descricao}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '100%', marginRight: '20px' }}
                      multiline
                      rows={4}
                      InputProps={{ readOnly: true }}
                    />

                    <br></br>
                    <b>Anexo:</b>
                    <br></br>
                    <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} />
                  </DialogContent>

                  <DialogActions>
                    {/* <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} /> */}
                    <Button onClick={handleCloseDialog}>Fechar</Button>
                  </DialogActions>

                </Dialog>
              </>
            )}


            {showUpdateCancelButtons && (
              <>
                <Dialog
                  open={openDialog}
                  onClose={handleClose}
                  PaperProps={{
                    component: 'form',
                    onSubmit: handleFinalize,
                  }}
                >
                  <DialogTitle>Finalizar Chamado</DialogTitle>
                  <DialogContent>
                    <Box border={0} borderColor="primary.main" padding={0.5} alignItems={'baseline'}>
                      <br></br>
                      <b>Dados:</b>
                      <br></br>
                      <TextField
                        label="Colaborador"
                        id="colaborador"
                        name="colaborador"
                        value={dados.colaborador}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ width: '40%', marginRight: '20px' }}
                        required
                      >
                      </TextField>

                      <TextField
                        label="Solicitante"
                        id="solicitante"
                        name="solicitante"
                        value={dados.solicitante}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ width: '40%', marginRight: '20px' }}
                        required
                        InputProps={{ readOnly: true }}
                      >
                      </TextField>

                      <TextField
                        label="Tarefa"
                        id="tarefa"
                        name="tarefa"
                        value={dados.tarefa}
                        onChange={handleChangeOpen}
                        margin="normal"
                        style={{ width: '40%', marginRight: '20px' }}
                        required
                      >
                      </TextField>

                      <TextField
                        label="Prazo Conclusão"
                        id="prazo"
                        name="prazo"
                        type="text"
                        value={dados.prazo}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label="Data Abertura"
                        id="dataTime_abertura"
                        name="dataTime_abertura"
                        type="text"
                        value={dados.dataTime_abertura}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Data Aceite"
                        id="dataTime_aceite"
                        name="dataTime_aceite"
                        type="text"
                        value={dados.dataTime_aceite}
                        onChange={handleChangeOpen}
                        margin="normal"
                        required
                        style={{ marginRight: '20px' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                    </Box>

                    <TextField
                      label="Descrição"
                      id="descricao"
                      name="descricao"
                      value={dados.descricao}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '100%', marginRight: '20px' }}
                      multiline
                      rows={4}
                    />

                    <br></br>
                    <b>Anexo Solicitante:</b>
                    <br></br>

                    <FileSaverComponent hexData={dados.anexo} fileName={dados.nome_arquivo} />
                    <br></br>
                    <Divider></Divider>
 
                
                    <b>Finalização?</b>
                    <br></br>
                    <TextField
                      label="Descrição Finalizacao"
                      id="descricao_finalizacao"
                      name="descricao_finalizacao"
                      value={dados.descricao_finalizacao}
                      onChange={handleChangeOpen}
                      margin="normal"
                      style={{ width: '100%', marginRight: '20px' }}
                      multiline
                      rows={4}
                    />


                    <br></br>
                    <b>Anexo</b>
                    <br></br>
                    <FormControl fullWidth>
                      <Input
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: 'file/*' }}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="file-upload">
                        <Button variant="contained" component="span" >
                          <AttachFileIcon></AttachFileIcon> {dados.anexo_finalize ? dados.anexo_finalize.name : 'Selecione um arquivo'}
                        </Button>
                      </label>
                    </FormControl>

                  </DialogContent>

                  <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleClose} type="submit">Finalizar</Button>
                  </DialogActions>

                </Dialog>
              </>
            )}
          </React.Fragment>
        ];
      },
    },
  ];
  return (
    <Box
      sx={{
        height: 600,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id_chamado}
      />
    </Box>
  );
}
