import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function ImageAvatars() {
  return (
    <Stack 
      direction="row" 
      spacing={2} 
      alignItems={'center'} 
      alignContent={'center'} 
      justifyContent={'center'}
      sx={{
        // maxWidth: '600px', // Definindo um tamanho máximo
        overflow: 'hidden' // Adicionando overflow para controlar o comportamento de transbordamento
      }}
      >
<Avatar
  alt="Marcelo"
  src="/static/images/avatar/1.jpg"
  sx={{ width: 56, height: 56 }}
  >
  </Avatar>
  <b>Marcelo</b>

  <Avatar
  alt="Fernando Borges"
  src="/static/images/avatar/1.jpg"
  sx={{ width: 56, height: 56 }}
  >
  </Avatar>

  <b>Fernando Borges</b>

  <Avatar
  alt="Marcio Gaio"
  src="/static/images/avatar/1.jpg"
  sx={{ width: 56, height: 56 }}
  >
  </Avatar>


  <b>Marcio Gaio</b>



    </Stack>
  );
}
