import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Dialog } from '@mui/material';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';
import * as process from 'process';

const initialRows: GridRowsProp = [
  {
    id_user: '',
    nome: '',
    email: '',
  },
];


export default function GridUser() {
  const [rows, setRows] = React.useState(initialRows);
  const [open, setOpen] = React.useState(false);

  const [showSaveCancelButtons, setShowSaveCancelButtons] = React.useState(true);
  const [showUpdateCancelButtons, setShowUpdateCancelButtons] = React.useState(false);


  const [dados, setDados] = React.useState({
    id_user: '',
    nome: '',
    email: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_USER_LIST_GRID}`,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`
          }
      });
        setRows(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    // Busca os dados inicialmente
    fetchData();

    // Configura o intervalo para buscar dados regularmente
    const interval = setInterval(fetchData, process.env.REACT_APP_INTERVAL_TIME); // A cada 5 segundos (5000 milissegundos)

    // Limpa o intervalo quando o componente é desmontado para evitar vazamento de memória
    return () => clearInterval(interval);
  }, []);

  const [openDialog, setOpenDialog] = React.useState(false);
  
  useEffect(() => {
    if (openDialog) {
    }
  }, [openDialog]);


  const handleEditClick = (id_user: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id_user === id_user);
    if (selectedRow) {
      setDados({
        id_user: selectedRow.id_user,
        nome: selectedRow.nome,
        email: selectedRow.email
      });
      setOpenDialog(true);
      setShowSaveCancelButtons(false);
      setShowUpdateCancelButtons(true);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };
  
  const handleShowClick = (id_user: GridRowId) => () => {
    const selectedRow = rows.find(row => row.id_user === id_user);
    if (selectedRow) {
      setDados({
        id_user: selectedRow.id_user,
        nome: selectedRow.nome,
        email: selectedRow.email
      });
      setOpenDialog(true);
      setShowSaveCancelButtons(true);
      setShowUpdateCancelButtons(false);
    } else {
      console.log('Linha não encontrada para este ID');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };

  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_CAD_TRUCK_NEW_REGISTER}`, dados, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
  //       }
  //     });
  //     // console.log(response.data); // Resposta do servidor (opcional)
  //   } catch (error) {
  //     console.error('Erro ao enviar formulário:', error);
  //   }
  // };
  

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 200,
      editable: false
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'id_user',
      headerName: 'ID',
      width: 300,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
      <React.Fragment>
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Delete"
            onClick={handleShowClick(id)}
            color="inherit"
          />
        {/* <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        /> */}



      <Dialog 
      open={openDialog} 
      onClose={handleCloseDialog}
      PaperProps={{
        component: 'form',
        // onSubmit: handleSubmit
      }}
      >
      <DialogTitle>Usuário</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informações usuário
          </DialogContentText>

          <TextField
            // fullWidth
            label="Nome"
            id="nome"
            name="nome"
            value={dados.nome}
            onChange={handleChange}
            margin="normal"
            // variant='standard'
            required
            style={{ marginRight: '20px' }}
          />
                    <TextField
            // fullWidth
            label="Email"
            id="email"
            name="email"
            value={dados.email}
            onChange={handleChange}
            margin="normal"
            // variant='standard'
            required
            style={{ marginRight: '20px' }}
          />
                    <TextField
            // fullWidth
            label="Senha"
            id="senha"
            name="senha"
            value={dados.id_user}
            onChange={handleChange}
            margin="normal"
            // variant='standard'
            required
            style={{ marginRight: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          {showSaveCancelButtons && (
            <>
              <Button onClick={handleCloseDialog}>Fechar</Button>
              {/* <Button onClick={handleCloseDialog} type="submit">Salvar</Button> */}
            </>
          )}
          {/* {showUpdateCancelButtons && (
            <>
              <Button onClick={handleCloseDialog}>Cancelar</Button>
              <Button onClick={handleCloseDialog} type="submit">Atualizar</Button>
            </>
          )} */}
        </DialogActions> 
      </Dialog>



      </React.Fragment>
        ];
      },
    },
  ];
  return (
    <Box
      sx={{
        height: 600,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id_user}
      />
    </Box>
  );
}
