import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useState } from 'react';
import axios from 'axios';
import getIdToken from '../../utils/api_loker.tsx';
import * as process from 'process';

export default function FormCadUser() {
  const [open, setOpen] = React.useState(false);

  const [dados, setDados] = useState({
    nome: '',
    email: '',
    senha: '',
    
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };

  const handleClickOpen = (event) => {
    setDados({
      nome: '',
      email: '',
      senha: '',
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CAD_USER}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} style={{}}>
        Novo Usuário
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
      >
        <DialogTitle>Novo Usuário</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha as informações necessárias para cadastrar um novo usuário
          </DialogContentText>

          <TextField
            // fullWidth
            label="Nome"
            id="nome"
            name="nome"
            value={dados.nome}
            onChange={handleChange}
            margin="normal"
            // variant='standard'
            required
            style={{ marginRight: '20px' }}
          />
                    <TextField
            // fullWidth
            label="Email"
            id="email"
            name="email"
            value={dados.email}
            onChange={handleChange}
            margin="normal"
            // variant='standard'
            required
            style={{ marginRight: '20px' }}
          />
                    <TextField
            // fullWidth
            label="Senha"
            id="senha"
            name="senha"
            type="password"
            value={dados.senha}
            onChange={handleChange}
            margin="normal"
            // variant='standard'
            required
            style={{ marginRight: '20px' }}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClose} type="submit">Cadastrar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}