import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import getIdToken from '../../../utils/api_loker.tsx';
import * as process from 'process';

export default function FormDialog_cad_CNH() {
  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState({
    n_cnh: '',
    n_cpf: '',
    data_validade: '',
    categoria: '',
    anexo: null as File | null
  });

  const handleClickOpen = () => {
    setDados({
      n_cnh: '',
      n_cpf: '',
      data_validade: '',
      categoria: '',
      anexo: null as File | null
    });
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      setDados({ ...dados, anexo: file });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CAD_CNH_NEW_REGISTER}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} style={{}}>
        Novo CNH
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Novo CNH</DialogTitle>
        <DialogContent>
          <Box border={0} borderColor="primary.main" padding={0.5} alignItems={'baseline'}>
            <br />
            <b>Dados:</b>
            <br />
            <TextField
              label="Número CNH"
              id="n_cnh"
              name="n_cnh"
              value={dados.n_cnh}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Número CPF"
              id="n_cpf"
              name="n_cpf"
              value={dados.n_cpf}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Validade"
              id="data_validade"
              name="data_validade"
              type="date"
              value={dados.data_validade}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Categoria"
              id="categoria"
              name="categoria"
              value={dados.categoria}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
            />
          </Box>

          <br />
          <b>Anexo:</b>
          <br />
          <FormControl fullWidth>
            <Input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: 'file/*' }}
              style={{ display: 'none' }}
            />
            <label htmlFor="file-upload">
              <Button variant="contained" component="span">
                <AttachFileIcon /> {dados.anexo ? dados.anexo.name : 'Selecione um arquivo'}
              </Button>
            </label>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClose} type="submit">Cadastrar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
