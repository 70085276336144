import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import getIdToken from '../../../utils/api_loker.tsx';
import * as process from 'process'
import axios from 'axios';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import AttachFileIcon from '@mui/icons-material/AttachFile';



export default function FormDialog_cad_CRLV() {
  const [open, setOpen] = useState(false);
  const [opcoesColab, setOpcoesColab] = useState([]);
  const [dados, setDados] = useState({
    renavam: '',
    placa: '',
    uf: '',
    exercicio: '',
    municipio: '',
    chassi: '',
    tipo: '',
    modelo: '',
    combustivel: '',
    cor: '',
    ano: '',
    cod_CLA: '',
    n_CRV: '',
    data: '',
    descricao: '',
    anexo: null as File | null
  });



  const handleClickOpen = (event) => {
    setDados({
      renavam: '',
      placa: '',
      uf: '',
      exercicio: '',
      municipio: '',
      chassi: '',
      tipo: '',
      modelo: '',
      combustivel: '',
      cor: '',
      ano: '',
      cod_CLA: '',
      n_CRV: '',
      data: '',
      descricao: '',
      anexo: null as File | null
    });
    setOpen(true);
  };


  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setDados({ ...dados, [name]: value });
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      setDados({ ...dados, anexo: file });
    }
  };


  const handleClose = () => {
    setOpen(false);
  };


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(dados).forEach((key) => {
      formData.append(key, dados[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_CAD_CRLV_NEW_REGISTER}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${await getIdToken()}` // Adicione o token de autenticação aqui
        }
      });
      // console.log(response.data); // Resposta do servidor (opcional)
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };


  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} style={{}}>
        Novo CRLV
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Novo CRLV</DialogTitle>
        <DialogContent>
          <Box border={0} borderColor="primary.main" padding={0.5} alignItems={'baseline'}>
            <br></br>
            <b>Dados:</b>
            <br></br>
            <TextField
              label="Placa"
              id="placa"
              name="placa"
              value={dados.placa}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Reanam"
              id="renavam"
              name="renavam"
              value={dados.renavam}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Chassi"
              id="chassi"
              name="chassi"
              value={dados.chassi}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
            />

            <TextField
              label="Número CRV"
              id="n_CRV"
              name="n_CRV"
              value={dados.n_CRV}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Ano"
              id="ano"
              name="ano"
              value={dados.ano}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="UF"
              id="uf"
              name="uf"
              value={dados.uf}
              onChange={handleChange}
              margin="normal"
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Município"
              id="municipio"
              name="municipio"
              value={dados.municipio}
              onChange={handleChange}
              margin="normal"
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Cor"
              id="cor"
              name="cor"
              value={dados.cor}
              onChange={handleChange}
              margin="normal"
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Exercício"
              id="exercicio"
              name="exercicio"
              value={dados.exercicio}
              onChange={handleChange}
              margin="normal"
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Modelo"
              id="modelo"
              name="modelo"
              value={dados.modelo}
              onChange={handleChange}
              margin="normal"
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Data"
              id="data"
              name="data"
              type="date"
              value={dados.data}
              onChange={handleChange}
              margin="normal"
              required
              style={{ marginRight: '20px' }}
              InputLabelProps={{ shrink: true }}

            />
            <TextField
              label="Código CLA"
              id="cod_CLA"
              name="cod_CLA"
              value={dados.cod_CLA}
              onChange={handleChange}
              margin="normal"
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Tipo"
              id="tipo"
              name="tipo"
              value={dados.tipo}
              onChange={handleChange}
              margin="normal"
              style={{ marginRight: '20px' }}
            />
            <TextField
              label="Combustível"
              id="combustivel"
              name="combustivel"
              value={dados.combustivel}
              onChange={handleChange}
              margin="normal"
              style={{ marginRight: '20px' }}
            />


            <TextField
              label="Descrição"
              id="descricao"
              name="descricao"
              value={dados.descricao}
              onChange={handleChange}
              margin="normal"
              style={{ width: '100%', marginRight: '20px' }}
              multiline
              rows={4}
            />
          </Box>



          <br></br>
          <b>Anexo:</b>
          <br></br>
          <FormControl fullWidth>
            <Input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: 'file/*' }}
              style={{ display: 'none' }}
            />
            <label htmlFor="file-upload">
              <Button variant="contained" component="span" >
                <AttachFileIcon></AttachFileIcon> {dados.anexo ? dados.anexo.name : 'Selecione um arquivo'}
              </Button>
            </label>
          </FormControl>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClose} type="submit">Cadastrar</Button>
        </DialogActions>

      </Dialog>
    </React.Fragment>
  );
}
