import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container} from '@mui/material';
import MenuLateral from '../components/menu.tsx';
import AppBar from '../components/Appbar.tsx';

import GridChamadoDEV from '../components/desenvolvedor/GridDEV.tsx';
import FormCadChamadoDEV from '../components/desenvolvedor/FormDialog_dev.tsx';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function Page_cadPostos() {

  return (
    <Container maxWidth={'xl'} style={{}}>
      <Box>
        <AppBar></AppBar>
        {/* <h6 style={{ color: 'white' }}>nada</h6> */}

        <Grid container spacing={2} columns={16}>
          <Grid item xs={3}>
            <Item>
              <MenuLateral></MenuLateral>
            </Item>
          </Grid>

          <Grid item xs={13} sx={{justifyContent: 'left'}}>
            <Item  sx={{justifyContent: 'left'}}>

              <Grid container spacing={2} columns={16}>
                <Grid item xs={13}>
 

<GridChamadoDEV></GridChamadoDEV>

                </Grid>

                <Grid item xs={3}>

<FormCadChamadoDEV></FormCadChamadoDEV>



                </Grid>
              </Grid>
              
               

            
            </Item>
          
          
          
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
