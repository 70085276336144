import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Page_cadDriver from './screens/CadDriver.tsx';
import Page_cadDoc from './screens/CadDoc.tsx'
import Page_cadPostos from './screens/cad_postos.tsx';
import Page_programacao from './screens/programacoes.tsx';
import Page_chamadosAbertos from './screens/chamados/ChamadosAbertos.tsx';
import Page_cad_produtos from './screens/cad_produtos.tsx';
import Page_cad_users from './screens/cad_users.tsx';
import Page_chamadosEmAndamento from './screens/chamados/ChamadosEmAndamento.tsx';
import Page_chamadosFinalizado from './screens/chamados/ChamadosFinalizado.tsx';
import DashboardPage from './screens/DashboardPage.tsx';
import PrivateRoute from './utils/PrivateRoute.tsx';
import ResponsiveGrid from './screens/CadTruck.tsx';
import Page_cadDoc_CNH from './screens/CadDoc_CNH.tsx';
import LoginPage from './screens/LoginPage.tsx';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/cadtruck",
    element: <PrivateRoute element={<ResponsiveGrid></ResponsiveGrid>} />
  },
  {
    path: "/dashboard",
    element: <PrivateRoute element={<DashboardPage></DashboardPage>} />
  },
  {
    path: "/teste",
    // element: <Formulario></Formulario>
  },
  {
    path: "/caddriver",
    element: <PrivateRoute element={<Page_cadDriver></Page_cadDriver>} />
  },
  {
    path: "/caddoccrlv",
    element: <PrivateRoute element={<Page_cadDoc></Page_cadDoc>} />
  },
  {
    path: "/caddoccnh",
    element: <PrivateRoute element={<Page_cadDoc_CNH></Page_cadDoc_CNH>} />
  },
  {
    path: "/desenvolvedor",
    element: <PrivateRoute element={<Page_cadPostos></Page_cadPostos>} />
  },
  {
    path: "/programacoes",
    element: <PrivateRoute element={<Page_programacao></Page_programacao>} />
  },
  {
    path: "/chamadosabertos",
    element: <PrivateRoute element={<Page_chamadosAbertos></Page_chamadosAbertos>} />
  },
  {
    path: "/chamadosemandamento",
    element: <PrivateRoute element={<Page_chamadosEmAndamento></Page_chamadosEmAndamento>} />
  },
  {
    path: "/chamadosfinalizado",
    element: <PrivateRoute element={<Page_chamadosFinalizado></Page_chamadosFinalizado>} />
  },
  {
    path: "/cad_produtos",
    element: <PrivateRoute element={<Page_cad_produtos></Page_cad_produtos>} />
  },
  {
    path: "/cad_users",
    element: <PrivateRoute element={<Page_cad_users></Page_cad_users>} />
  },
  {
    path: "/login",
    element: <LoginPage />
  }
]);


//nunca mais mexe
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
