import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Button, colors } from '@mui/material';
import MenuLateral from '../components/menu.tsx';
import AppBar from '../components/Appbar.tsx';
import BasicLabel from '../components/dashboard/BarChart_Chamados.tsx';
import PieLabel from '../components/dashboard/PieChart_ChamadosSetor.tsx';
import BasicLineChart from '../components/dashboard/LineChart_Exemple.tsx';
import SimpleAreaChart from '../components/dashboard/AreaChart_Exemple.tsx';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function Page() {

  return (
    <Container maxWidth={'xl'} style={{}}>

      <Box>
        <AppBar></AppBar>
        {/* <h6 style={{ color: 'white' }}>nada</h6> */}


        <Grid container spacing={2} columns={16}>
          <Grid item xs={3}>
            <Item>
              <MenuLateral></MenuLateral>
            </Item>
          </Grid>

          <Grid item xs={6}>
            <h1>
              Chamados
            </h1>
            <Item>
              <BasicLabel></BasicLabel>
            </Item>

            <Item>
              <PieLabel></PieLabel>
            </Item>
          </Grid>


          <Grid item alignItems={'center'} xs={6}>
          <h1>
              Operações
            </h1>
            <Item>
              <BasicLineChart></BasicLineChart>
            </Item>

            <Item>
              <SimpleAreaChart></SimpleAreaChart>
            </Item>
          </Grid>
        </Grid>





      </Box>
    </Container>
  )
}
