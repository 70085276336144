import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import MenuLateral from '../../components/menu.tsx';
import AppBar from '../../components/Appbar.tsx';

import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';

import FormCadChamado from '../../components/chamados/FormDialog_chamados.tsx'
import GridChamado from '../../components/chamados/GridChamados.tsx'



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function Page_chamadosAbertos() {

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log(user);
      } else {
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);



  return (
    <Container maxWidth={'xl'} style={{}}>
      <Box>
        <AppBar></AppBar>
        {/* <h6 style={{ color: 'white' }}>nada</h6> */}

        <Grid container spacing={2} columns={16}>
          <Grid item xs={3}>
            <Item>
              <MenuLateral></MenuLateral>
            </Item>
          </Grid>

          <Grid item xs={13} sx={{ justifyContent: 'left' }}>
            <Item sx={{ justifyContent: 'left' }}>

              <Grid container spacing={2} columns={21}>
                <Grid item xs={18}>
                  <GridChamado></GridChamado>
                </Grid>
                

                <Grid item xs={3}>
                    <FormCadChamado>
                    </FormCadChamado>
                </Grid>


              </Grid>

              <Grid container spacing={2} columns={21}>
                <Grid item xs={9}>
                  {/* <GridChamado></GridChamado> */}
                </Grid>

                <Grid item xs={9}>
                  {/* <GridChamado></GridChamado> */}
                </Grid>
                

                <Grid item xs={3}>
                    {/* <FormCadChamado>
                    </FormCadChamado> */}
                </Grid>


              </Grid>




            </Item>



          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
