import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export default function PasswordReset() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClickOpen = () => {
    setEmail('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Email de redefinição de senha enviado');
      handleClose();
    } catch (error) {
      console.error('Erro ao enviar email de redefinição de senha:', error);
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Redefinição de Senha
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit
        }}
      >
        <DialogTitle>Redefinir Senha</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Insira seu email para receber um link de redefinição de senha
          </DialogContentText>
          <TextField
            label="Email"
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={handleChange}
            margin="normal"
            required
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit">Enviar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
